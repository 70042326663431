/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
import getNestedObjectFromKey from 'lodash.get'
import { dayjsMethod, getSeparateDateAndHour } from '../../utils/date'
import { logoHubee } from './imgBase64'
import translateIntlMsgString from '../../utils/translateIntlMsgString'

////////////////////////////////////////////////////////////////////////
// Break page with y position
////////////////////////////////////////////////////////////////////////

export const pageBreakConditions = (doc, page, y) => {
	const pageNumber = doc.internal.getNumberOfPages()
	const lastYposition = doc.previousAutoTable.finalY
	if (lastYposition <= y && pageNumber <= page) {
		doc.addPage()
	}
}

export const pageBreakYposition = (doc, y) => {
	const lastYposition = doc.lastAutoTable.finalY

	if (lastYposition <= y) {
		doc.addPage()
		doc.autoTable()
	}
}

////////////////////////////////////////////////////////////////////////
// DISPLAY TITLE PDF
////////////////////////////////////////////////////////////////////////

export const headerFooterPDF = (doc, nbPage, i, title) => (data) => {
	// HEADER PDF ///////////////////////////////////

	// Title forms
	doc.setFont('helvetica', 'bold')
	doc.setFontSize(11)
	doc.text(title, 195, 18, { align: 'right' })

	// logo
	// HELP -->  addImage(imageData, format, x, y, width, height, alias, compression, rotation)
	doc.addImage(logoHubee, 'PNG', data.settings.margin.left - 4, 10, 38, 14)

	// FOOTER PDF ///////////////////////////////////

	// Display number pages
	let str = 'Page ' + i
	if (typeof doc.putTotalPages === 'function') {
		str = str + ' / ' + nbPage
	}
	doc.setFontSize(7)
	doc.setFont('helvetica', 'normal')
	doc.text(str, 195, doc.internal.pageSize.height - 15, { align: 'right' })

	// Display copyright
	doc.text(
		`© ${new Date().getFullYear()} - Powered by HubeeConnect - All Rights Reserved.`,
		data.settings.margin.left,
		doc.internal.pageSize.height - 15
	)
}

////////////////////////////////////////////////////////////////////////
// ADD CSS TITLE BLOCK
////////////////////////////////////////////////////////////////////////

export const cssTitleBlock = (data, fontSizeTile, fontSizeBody, row = 1) => {
	if (data.row.index === row) {
		data.cell.styles.fontSize = fontSizeTile
		data.cell.styles.fontStyle = 'bold'
	} else {
		data.cell.styles.fontSize = fontSizeBody
	}
}

////////////////////////////////////////////////////////////////////////
// ADD CSS TITLE CELL
////////////////////////////////////////////////////////////////////////

export const cssTitleCell = (data, row = 1) => {
	if (
		(data.row.index > row && data.column.index === 1) ||
		data.column.index === 2 ||
		data.column.index === 3
	) {
		data.cell.styles.textColor = '#767676'
	}
}

////////////////////////////////////////////////////////////////////////
// DISPLAY TITLE PDF
////////////////////////////////////////////////////////////////////////

export const displayTitlePDF = (
	doc,
	title: string,
	options: any,
	nbTitle
): void => {
	doc.autoTable({
		margin: { top: 30, bottom: 30 },

		startY: doc.lastAutoTable.finalY + 4,

		body: [[`0${nbTitle || 0}`, '', title]],
		bodyStyles: {
			cellPadding: options?.cellPadding || 1,
			valign: 'middle',
		},

		columnStyles: {
			0: {
				columnWidth: 10,
				fillColor: '#f13a30',
				fontSize: '10',
				textColor: 'white',
				halign: 'center',
				valign: 'middle',
			},
			1: {
				columnWidth: 2,
				fillColor: '#fff',
			},
			2: {
				fillColor: '#132142',
				textColor: 'white',
				fontSize: '10',
				valign: 'middle',
				cellPadding: { right: 5, left: 2.5, top: 2, bottom: 2 },
			},
		},

		didDrawCell: function (data) {
			// Optional -> display a icon to title (first cell & row)
			if (
				options?.img?.img &&
				data.row.index === 0 &&
				data.column.index === 0
			) {
				const { imgWidth, imgHeight, img } = options.img
				const { height, width, x, y } = data.cell

				const centerY = y + (height - imgWidth) / 2
				const centerX = x + (width - imgHeight) / 2

				//  addImage(imageData, format, x, y, width, height, alias, compression, rotation)
				doc.addImage(img, 'PNG', centerX, centerY, imgWidth, imgHeight)
			}
		},
	})

	// add blank autotable to fix margin pb
	doc.autoTable()
}

////////////////////////////////////////////////////////////////////////
// MAPPING STARS RATING
////////////////////////////////////////////////////////////////////////

export const mappingRatingStars = {
	['0']: ['empty', 'empty', 'empty', 'empty', 'empty'],
	['05']: ['demi', 'empty', 'empty', 'empty', 'empty'],
	['1']: ['full', 'empty', 'empty', 'empty', 'empty'],
	['15']: ['full', 'demi', 'empty', 'empty', 'empty'],
	['2']: ['full', 'full', 'empty', 'empty', 'empty'],
	['25']: ['full', 'full', 'demi', 'empty', 'empty'],
	['3']: ['full', 'full', 'full', 'empty', 'empty'],
	['35']: ['full', 'full', 'full', 'demi', 'empty'],
	['4']: ['full', 'full', 'full', 'full', 'empty'],
	['45']: ['full', 'full', 'full', 'full', 'demi'],
	['5']: ['full', 'full', 'full', 'full', 'full'],
}

////////////////////////////////////////////////////////////////////////
// DAW SEPARATOR LINE
////////////////////////////////////////////////////////////////////////
export const drawSeparatorLine = (doc, Yposition) => {
	// HELP -> line(x1, y1, x2, y2, style)
	doc.setLineWidth(0.05)
	doc.setDrawColor('#c0c0c0')
	doc.line(25, Yposition, 185, Yposition)
	doc.setLineWidth(0.05)
	doc.setDrawColor('white')
	doc.line(25, Yposition + 0.5, 185, Yposition + 0.5)
}

////////////////////////////////////////////////////////////////////////

export const dateToFrom = (data, tree) => {
	const dateTo = getNestedObjectFromKey(data, tree[0])
	const dateFrom = getNestedObjectFromKey(data, tree[1])

	const { date: dateDateFrom, hour: hourDateFrom } =
		getSeparateDateAndHour(dateFrom)

	const { date: dateDateTo, hour: hourDateTo } = getSeparateDateAndHour(dateTo)

	if (!dayjsMethod(dateTo).isSame(dateFrom, 'day')) {
		return `${dateDateFrom} : ${hourDateFrom} - ${dateDateTo} : ${hourDateTo}`
	} else {
		return `${dateDateFrom} ${hourDateFrom} - ${hourDateTo}`
	}
}

/////////////////////////////////////////////////////////
// Intervention Transport date
/////////////////////////////////////////////////////////

export const dateTransport = ({ date, timeTo, timeFrom }) => {
	const { date: dateDateFrom } = getSeparateDateAndHour(date) || {}
	const { hour: hourDateFrom } = getSeparateDateAndHour(timeFrom) || {}
	const { hour: hourDateTo } = getSeparateDateAndHour(timeTo) || {}

	if (timeTo) {
		return `${dateDateFrom} entre ${hourDateFrom} et ${hourDateTo}`
	} else {
		return `${dateDateFrom} à ${hourDateFrom}`
	}
}

/////////////////////////////////////////////////////////
// Transport address
/////////////////////////////////////////////////////////

export const formatAddressTransport = (transport) => {
	const { name, address, city, zipcode, country } = transport
	return `${name} - ${address} - ${zipcode} ${city}, ${country?.substring(
		0,
		2
	)}`
}

/////////////////////////////////////////////////////////
// Control address
/////////////////////////////////////////////////////////

export const formatAddressIntervention = (intervention) => {
	const address = {
		city: intervention?.isOrderEqualFinal
			? intervention?.orderingSite?.city
			: intervention?.interventionControlFinalCustomer?.finalSite?.city,
		address: intervention?.isOrderEqualFinal
			? intervention?.orderingSite?.address
			: intervention?.interventionControlFinalCustomer?.finalSite?.address,
		zipcode: intervention?.isOrderEqualFinal
			? intervention?.orderingSite?.zipcode
			: intervention?.interventionControlFinalCustomer?.finalSite?.zipcode,
		country: intervention?.isOrderEqualFinal
			? intervention?.orderingSite?.country
			: intervention?.interventionControlFinalCustomer?.finalSite?.country,
	}

	return intervention?.interventionControlFinalCustomer?.finalAddressText
		? intervention?.interventionControlFinalCustomer?.finalAddressText
		: `${address?.address} - ${address?.zipcode} - ${
				address?.city
		  } , ${address?.country?.substring(0, 2)}`
}

/////////////////////////////////////////////////////////
// format annotation
/////////////////////////////////////////////////////////

export const mapAnnotations = (annotationsArr, namePhotos, annotationTypes) => {
	let annotations = ''

	annotationsArr.forEach(({ comment, type }, i) => {
		const code = annotationTypes.formAnnotationTypes.find(
			({ id }) => id === type
		)?.code

		annotations = `${annotations} | ${i + 1} - ${
			type ? translateIntlMsgString(`formTemplate.${code || type}`) : ''
		} ${comment ?? ''}`
	})

	return `${namePhotos} ${annotations}`
}
