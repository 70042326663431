import React, { useCallback } from 'react'

import { jsPDF } from 'jspdf'
require('jspdf-autotable')
import { UserOptions } from 'jspdf-autotable'
import { Button } from '@mui/material'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'

import { formatDataPdfControl } from './formatData/formatDataPdf/formatDataPdfControl'
import { useFormatMessage } from '../../utils/hooks'
import { generateBlockPdf } from './generateBlockPdf'
import { headerFooterPDF } from './utilPdf'
import { formatDataPdfTransport } from './formatData/formatDataPdf/formatDataPdfTransport'
import { EInterventionTypes } from '../../utils/app-models'
import { useQuery } from '@apollo/client'
import { FORM_ANNOTATION_TYPE } from '../../api/gql/queries/interventionFormsQueries'

const formatDataPDF = {
	[EInterventionTypes.transport]: formatDataPdfTransport,
	[EInterventionTypes.control]: formatDataPdfControl,
	[EInterventionTypes.service]: formatDataPdfControl,
}

interface jsPDFCustom extends jsPDF {
	autoTable: (options: UserOptions) => void
}

interface IExportBtn {
	data
}

const ExportBtn: React.FC<IExportBtn> = ({ data }) => {
	const intlMsg = useFormatMessage()

	const { data: annotationTypes } = useQuery(FORM_ANNOTATION_TYPE)

	const generatePDF = useCallback(async () => {
		const interventionType = data?.intervention?.interventionType

		// init JSPDF
		const doc = new jsPDF({ compress: true }) as jsPDFCustom as any

		const totalPagesExp = '{total_pages_count_string}' // need to calculated the current page

		// format data before display block
		const resultDataPDF = formatDataPDF[interventionType](
			data,
			intlMsg,
			annotationTypes
		)

		// Get all images blocks
		const imageBlocks = resultDataPDF.blocksAllRecords.filter(
			(record) => record.typeBlock === 'picture'
		)

		// Load all images and get their dimensions
		function ImageWithSize(block) {
			const url = block.record.rec.value
			return new Promise((resolve, reject) => {
				const img = new Image()
				img.onload = () => {
					// Add the dimensions to the block
					block.record.rec.width = img.naturalWidth
					block.record.rec.height = img.naturalHeight
					resolve(block)
				}
				img.onerror = () => reject(new Error(`Failed to load image at ${url}`))
				img.src = url
			})
		}
		Promise.all(imageBlocks.map(ImageWithSize))
			.then(async () => {
				// Generate the PDF with Size image added
				generateBlockPdf(interventionType, resultDataPDF, doc)

				// Add header and footer
				const nbPage = doc.internal.getNumberOfPages()
				for (let i = 1; i <= nbPage; i++) {
					doc.setPage(i)
					doc.autoTable({
						didDrawPage: headerFooterPDF(
							doc,
							nbPage,
							i,
							data?.formTemplate?.title
						) as any,
					})
				}

				if (typeof doc.putTotalPages === 'function') {
					doc.putTotalPages(totalPagesExp)
				}

				doc.save(`formulaires-${data.reference}.pdf`)
			})
			.catch((error) => {
				console.error(
					'Erreur lors de la récupération des dimensions des images :',
					error
				)
			})
	}, [])

	return (
		<Button
			variant='contained'
			color='primary'
			onClick={generatePDF}
			startIcon={<PictureAsPdfIcon />}
		>
			PDF
		</Button>
	)
}

export default ExportBtn
