/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types

import { globalConfigPDF } from '../globalConfigPDF'

const yBreakPage = 170

const { margin, fontSizeBody, widthImgAnnotation, heightImgAnnotation } =
	globalConfigPDF

export const blockImage = (doc, title, record) => {
	// Set the size of the image according to the orientation of the image
	let heightSize = heightImgAnnotation
	let widthSize = widthImgAnnotation

	const max = { height: 128, width: 96, ratio: 96 / 128 }
	const ratio = record?.rec?.width / record?.rec?.height

	if (max.ratio > ratio) {
		heightSize = max.height
		widthSize = max.height * ratio
	} else {
		widthSize = max.width
		heightSize = max.width / ratio
	}

	// need to break page if there is no space to display the whole image block
	const lastYposition = doc.lastAutoTable.finalY

	if (lastYposition >= yBreakPage) {
		doc.addPage()
		doc.autoTable()
	}

	doc.autoTable({
		body: title,
		margin: { top: margin, bottom: margin },
		allSectionHooks: true,
		// tableWidth: 'auto',
		columnStyles: { 0: { cellWidth: widthSize } },

		// Block position start Y
		startY: lastYposition >= yBreakPage ? '' : doc.lastAutoTable.finalY + 1,

		didParseCell: (data) => {
			data.cell.styles.fontSize = fontSizeBody
			if (data.row.index === 1 || data.row.index === 3) {
				data.row.height = heightSize
			}
		},

		didDrawCell: async function (data) {
			const { x: positionXcell, y: positionYcell } = data.cell
			const { value, annotations } = record.rec

			if (
				(data.row.index === 1 && value?.length) ||
				(data.row.index === 3 && value?.length)
			) {
				// TYPE FIELD = IMAGE /////////////////////////////////////////////////////////////////////

				doc.addImage(
					`/img/gabarit-vehicle/pdf-${record?.angleType ?? 'none'}.jpg`,
					'JPEG',
					positionXcell,
					positionYcell,
					widthSize,
					heightSize,
					'',
					'FAST'
				)

				const image = new Image()
				image.src = value
				image.crossOrigin = 'anonymous'

				doc.addImage(
					image,
					'JPEG',
					positionXcell,
					positionYcell,
					widthSize,
					heightSize,
					'',
					'FAST'
				)

				// TODO try/Catch does not work because we receive a aws link value and if the img not exist we don't catch the error
				// to solve et catch error, maybe delete in database all aws link where image does not exist (if value is null or empty we catch an error) ???

				// try {
				// 	doc.addImage(
				// 		value,
				// 		'JPEG',
				// 		positionXcell,
				// 		positionYcell,
				// 		widthImgAnnotation,
				// 		heightImgAnnotation
				// 	)
				// } catch (error) {
				// 	doc.addImage(
				// 		`/img/gabarit-vehicle/pdf-${record?.angleType ?? 'none'}.jpg`,
				// 		'JPEG',
				// 		positionXcell,
				// 		positionYcell,
				// 		widthImgAnnotation,
				// 		heightImgAnnotation
				// 	)
				// }

				const sizeCircleNumber = 3

				annotations?.forEach(({ x1, x2, y1, y2 }, i) => {
					// defines the values according to the starting point of the strapping
					const y1_m = y2 < y1 ? y2 : y1
					const y2_m = y2 < y1 ? y1 : y2
					const x1_m = x2 < x1 ? x2 : x1
					const x2_m = x2 < x1 ? x1 : x2

					const left = x1_m * widthSize
					const top = y1_m * heightSize
					const width = Math.abs((x2_m - x1_m) * widthSize)
					const height = Math.abs((y2_m - y1_m) * heightSize)
					// Add annotation ellipse
					doc.setLineWidth(0.4)
					doc.setDrawColor('#e00b28')
					doc.ellipse(
						positionXcell + width / 2 + left,
						positionYcell + height / 2 + top,
						width / 2,
						height / 2
					)

					// Add annotation Bg ellipse
					doc.setDrawColor(0)
					doc.setFillColor('#e00b28')
					doc.ellipse(
						positionXcell + width / 2 + left,
						positionYcell + top - 3.5,
						sizeCircleNumber,
						sizeCircleNumber,
						'F'
					)

					// Add number txt
					doc.setTextColor('white')
					doc.text(
						(i + 1).toString(),
						positionXcell + width / 2 + left - sizeCircleNumber / 2 + 0.5,
						positionYcell + top - 3.5,
						{
							baseline: 'middle',
						}
					)
				})
			}
		},
	})
}
