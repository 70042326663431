import React, { useCallback, useEffect, useState } from 'react'

import { jsPDF } from 'jspdf'
import 'jspdf-autotable'
import { CircularProgress } from '@mui/material'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import Button from '../../buttons/CustomButton/CustomButton'

import { useFormatMessage } from '../../../utils/hooks'
import { UserOptions } from 'jspdf-autotable'
import { generateBlockPdf } from '../generateBlockPdf'
import { headerFooterPDF } from '../utilPdf'
import {
	FORM_ANNOTATION_TYPE,
	useLazyFormInterventionPDF,
} from '../../../api/gql/queries/interventionFormsQueries'
import { EInterventionTypes } from '../../../utils/app-models'
import { formatDataPdfControl } from '../formatData/formatDataPdf/formatDataPdfControl'
import { formatDataPdfTransport } from '../formatData/formatDataPdf/formatDataPdfTransport'
import { useQuery } from '@apollo/client'

const formatDataPDF = {
	[EInterventionTypes.transport]: formatDataPdfTransport,
	[EInterventionTypes.control]: formatDataPdfControl,
	[EInterventionTypes.service]: formatDataPdfControl,
}

const buttonCSS = {
	color: 'rgba(0, 0, 0, 0.60)',
	borderColor: 'rgba(128, 128, 128, 0.31)',
	fontWeight: 600,
}

interface jsPDFCustom extends jsPDF {
	autoTable: (options: UserOptions) => void
}

interface IBtnDownloadFormPDF {
	id
	reference: string
}

const BtnDownloadFormPDF: React.FC<IBtnDownloadFormPDF> = ({
	id,
	reference,
}) => {
	const intlMsg = useFormatMessage()
	const [PDFisLoading, setPDFisLoading] = useState(false)
	const { data: annotationTypes } = useQuery(FORM_ANNOTATION_TYPE)

	const { getForm, data, loading } = useLazyFormInterventionPDF({
		fetchPolicy: 'cache-and-network',
	})
	// Get data form
	const downloadPdf = useCallback(() => {
		setPDFisLoading(true)
		getForm({ variables: { id } })
	}, [id])

	// Generate PDF
	useEffect(() => {
		if (PDFisLoading && data && !loading) {
			const interventionType = data?.intervention?.interventionType

			const generatePDF = async () => {
				// init JSPDF
				const doc = new jsPDF({ compress: true }) as jsPDFCustom as any

				const totalPagesExp = '{total_pages_count_string}' // need to calculated the current page

				// format data before display block
				const resultDataPDF = formatDataPDF[interventionType](
					data,
					intlMsg,
					annotationTypes
				)

				// Get all images blocks
				const imageBlocks = resultDataPDF.blocksAllRecords.filter(
					(record) => record.typeBlock === 'picture'
				)

				// Load all images and get their dimensions
				function ImageWithSize(block) {
					const url = block.record.rec.value
					return new Promise((resolve, reject) => {
						const img = new Image()
						img.onload = () => {
							// Add the dimensions to the block
							block.record.rec.width = img.naturalWidth
							block.record.rec.height = img.naturalHeight
							resolve(block)
						}
						img.onerror = () =>
							reject(new Error(`Failed to load image at ${url}`))
						img.src = url
					})
				}
				Promise.all(imageBlocks.map(ImageWithSize))
					.then(() => {
						// Generate the PDF with Size image added
						generateBlockPdf(interventionType, resultDataPDF, doc)

						// Add header and footer
						const nbPage = doc.internal.getNumberOfPages()
						for (let i = 1; i <= nbPage; i++) {
							doc.setPage(i)
							doc.autoTable({
								didDrawPage: headerFooterPDF(
									doc,
									nbPage,
									i,
									data?.formTemplate?.title
								) as any,
							})
						}

						if (typeof doc.putTotalPages === 'function') {
							doc.putTotalPages(totalPagesExp)
						}

						doc.save(`formulaires-${reference}.pdf`)
					})
					.catch((error) => {
						console.error(
							'Erreur lors de la récupération des dimensions des images :',
							error
						)
					})
					.finally(() => {
						setPDFisLoading(false) // Reset loading state
					})
			}

			generatePDF()
		}
	}, [data, loading, id, PDFisLoading])

	return (
		<Button
			onClick={downloadPdf}
			size='small'
			variant='outlined'
			style={buttonCSS}
		>
			{PDFisLoading ? (
				<CircularProgress size={20} />
			) : (
				<>
					<PictureAsPdfIcon /> {intlMsg('misc.downloadPdf')}
				</>
			)}
		</Button>
	)
}

export default BtnDownloadFormPDF
