import React, { useCallback, useMemo, useState } from 'react'

import { useQuery } from '@apollo/client'

import {
	FormControlLabel,
	Grid,
	Switch,
	Typography,
	Divider,
	Stack,
	Box,
	IconButton,
	Alert,
	Modal,
	Backdrop,
	Fade,
} from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import CloseIcon from '@mui/icons-material/Close'

import { useFormatMessage } from '../../../../../../utils/hooks'
import PictureAnnotation from './PictureAnnotation'
import { FORM_ANNOTATION_TYPE } from '../../../../../../api/gql/queries/interventionFormsQueries'

const modalCSS = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}

const paperCSS = {
	backgroundColor: 'white',
	boxShadow: 5,
	width: '98%',
	height: '96%',
	borderRadius: 1,
	outline: 'none',
	zIndex: 1,
}

const annotationNbCSS = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	backgroundColor: '#dd3444',
	width: '20px',
	textAlign: 'center',
	borderRadius: '50%',
	color: 'white',
	mr: 2,
}

const commentContentCSS = {
	display: 'flex',
	alignItems: 'center',
	p: 2,
	borderBottom: 'solid 1px #dddddd8c',
	transition: '0.4s',
	'&:hover': {
		backgroundColor: '#dddddd40',
		borderRadius: 1,
		transition: '0.4s',
	},
}

const overflowCSS = { overflowY: 'scroll', overflowX: 'hidden' }
const titleComment = { fontWeight: 500 }
const iconCSS = { color: ({ palette }) => palette.error.dark, mr: 0.5 }
const annotationLayerCSS = { position: 'relative', overflow: 'hidden' }

interface IModalAnnotation {
	handleClose
	open
	handleImageLoaded
	imgURL
	angleType
	name?: string
	geolocation
	annotations
	isDocument: boolean
}

const ModalAnnotation: React.FC<IModalAnnotation> = ({
	handleClose,
	open,
	handleImageLoaded,
	imgURL,
	angleType,
	name,
	geolocation,
	annotations,
	isDocument,
}) => {
	const intlMsg = useFormatMessage()
	const [checked, setChecked] = useState(true)

	// Get list annotations type available
	const { data: listAnnotationType } = useQuery(FORM_ANNOTATION_TYPE)

	const processedAnnotation = useMemo(
		() =>
			annotations?.map((annotation) => ({
				...annotation,
				code: listAnnotationType?.formAnnotationTypes?.find(
					(annotationType) =>
						annotation.type === annotationType?.id.split('/').pop() ||
						annotation.type === annotationType?.id
				)?.code,
			})) || [],
		[annotations, listAnnotationType]
	)

	const handleMaskAnnotation = useCallback(
		(currentIndex, action) => () => {
			if (checked) {
				const currentAnnotation = document?.getElementById(
					`annotation-${currentIndex}`
				) as any

				const allAnnotations = document.querySelectorAll(
					'.annotationForms'
				) as any

				allAnnotations?.forEach((annotation) => {
					if (annotation.id === currentAnnotation.id || action === 'leave') {
						annotation.classList.remove('maskAnnotation')
					} else {
						annotation.classList.add('maskAnnotation')
					}
				})
			}
		},
		[checked]
	)

	const toggleDisplayAllAnnotations = useCallback(() => {
		const allAnnotations = document.querySelectorAll('.annotationForms') as any

		allAnnotations?.forEach((annotation) => {
			if (checked) {
				annotation.style.opacity = '0'
			} else {
				annotation.style.opacity = '1'
			}
		})

		setChecked((prev) => !prev)
	}, [checked])

	return (
		<Modal
			sx={modalCSS}
			open={open}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{ timeout: 500 }}
		>
			<Fade in={open}>
				<Stack sx={paperCSS}>
					<Stack
						direction='row'
						alignItems='center'
						justifyContent='space-between'
						p={3}
					>
						<Stack direction='row' alignItems='center' spacing={2}>
							<Typography variant='h1'>
								{name ?? intlMsg(`formTemplate.${angleType}`)}
							</Typography>

							{!isDocument && (
								<>
									<Divider orientation='vertical' flexItem />

									<FormControlLabel
										control={
											<Switch
												size='small'
												checked={checked}
												onChange={toggleDisplayAllAnnotations}
												color='primary'
											/>
										}
										label={intlMsg(`interventionForms.displayAnnotations`)}
									/>
								</>
							)}
						</Stack>

						<IconButton aria-label='close' onClick={handleClose} size='large'>
							<CloseIcon />
						</IconButton>
					</Stack>

					<Box sx={overflowCSS}>
						<Grid container spacing={3} sx={{ p: 3, pt: 0 }}>
							<Grid item xs={12} lg={isDocument ? 12 : 8}>
								<Box sx={annotationLayerCSS}>
									<PictureAnnotation
										handleImageLoaded={handleImageLoaded}
										imgURL={imgURL}
										annotationNumber
										isModal
										angleType={angleType}
									/>
								</Box>

								<Stack direction='row' alignItems='center' mt={1}>
									{geolocation?.latitude && (
										<a
											href={`https://www.google.com/maps/?q=${geolocation.latitude},${geolocation.longitude}`}
											target='_blank'
											rel='noreferrer'
										>
											<Typography
												variant='caption'
												sx={{ display: 'flex', alignItems: 'center' }}
											>
												<LocationOnIcon sx={iconCSS} fontSize='small' />
												{`${Number.parseFloat(geolocation.latitude).toFixed(
													7
												)}, ${Number.parseFloat(geolocation.longitude).toFixed(
													7
												)}`}
											</Typography>
										</a>
									)}
								</Stack>
							</Grid>

							{!isDocument && (
								<Grid item xs={12} lg={4}>
									{processedAnnotation?.length ? (
										processedAnnotation?.map(({ comment, type, code }, i) => (
											<Box
												sx={commentContentCSS}
												key={i}
												onMouseEnter={handleMaskAnnotation(i, 'enter')}
												onMouseLeave={handleMaskAnnotation(i, 'leave')}
											>
												<Box sx={annotationNbCSS}>{i + 1}</Box>

												<Stack direction='row' spacing={1} alignItems='center'>
													<Typography sx={titleComment} variant='subtitle1'>
														{intlMsg(`formTemplate.${code || type || '-'}`)}
													</Typography>
													<Typography variant='body1'>{comment}</Typography>
												</Stack>
											</Box>
										))
									) : (
										<Alert severity='info'>
											{intlMsg('interventionForms.noDefaults')}
										</Alert>
									)}
								</Grid>
							)}
						</Grid>
					</Box>
				</Stack>
			</Fade>
		</Modal>
	)
}

export default ModalAnnotation
